import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import DateTime from 'luxon/src/datetime';
import { useTranslation } from 'react-i18next';
import PublicChat from '../StaffSpace/PublicChat';
import Video from '../../components/UI/Video';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import usePubsub from '../../Lib/usePubsub';
import LiveNanoPlayer from './LiveNanoPlayer';
import LiveSondage from './LiveSondage';
import useRestClient from '../../Lib/useRestClient';
import QuestionAnswers from './QuestionAnswers';
import LiveYoutube from './LiveYoutube';

const VIEW_CHAT = 'CHAT';
const VIEW_POLLS = 'POLLS';
const VIEW_QA = 'QA';

const Plenary = ({}) => {
  const [channelIndex, setChannelIndex] = useState(0);
  const { t } = useTranslation();
  const idSondagesRepondus = useSelector((state) => state.stateDataReducer.idSondagesRepondus);
  const [lastAction, setLastAction] = useState(DateTime.local());
  const [view, setView] = useState(VIEW_CHAT);
  const config = useSelector((state) => state.stateDataReducer.config);
  const isFinished = parseInt(useSelector((state) => state.stateDataReducer.config.isFinished)) === 1;

  const [currentSondage] = useRestClient('current-sondage', null, [lastAction]);

  usePubsub('polls', () => {
    setLastAction(DateTime.local());
  });

  useEffect(() => {
    if (currentSondage) {
      const dontHaveSondages = idSondagesRepondus && idSondagesRepondus.includes(currentSondage.id);
      if (view !== VIEW_POLLS && !!currentSondage && !dontHaveSondages) {
        setView(VIEW_POLLS);
      } else if (view === VIEW_POLLS && !currentSondage || dontHaveSondages) {
        setView(VIEW_CHAT);
      }
    }
  }, [idSondagesRepondus, currentSondage]);

  usePubsub('refresh', () => {
    document.location.reload();
  });

  if (isFinished) {
    return (
      <div className="component-live-finished">
        {config.endVideoUrl && <Video url={config.endVideoUrl} />}
      </div>
    );
  }

  const allChannels = [
    {
      name: 'Plenary',
      langs: ['EN', 'AR', 'ES', 'CH'],
      channels:  [[
          "https://www.youtube.com/embed/0KT9v_NFfak?si=DO_hfEPMjtxHzWtn&autoplay=1",
          "https://www.youtube.com/embed/jey5mNe44vk?si=DO_hfEPMjtxHzWtn&autoplay=1",
          "https://www.youtube.com/embed/hMd1MxwxqOo?si=DO_hfEPMjtxHzWtn&autoplay=1",
          "https://www.youtube.com/embed/YIirf36DGBU?si=DO_hfEPMjtxHzWtn&autoplay=1",
        ], [
          "https://www.youtube.com/embed/CnUrMlHPJGc?si=DO_hfEPMjtxHzWtn&autoplay=1",
          "https://www.youtube.com/embed/1MhcL3eGS5k?si=DO_hfEPMjtxHzWtn&autoplay=1",
          "https://www.youtube.com/embed/b2qF4k_Mkwo?si=DO_hfEPMjtxHzWtn&autoplay=1",
          "https://www.youtube.com/embed/1uU6AZ8OnGY?si=DO_hfEPMjtxHzWtn&autoplay=1",
        ],
      ]
    }, {
      name: 'XPRIZE Healthspan Launch',
      langs: ['EN', 'AR', 'ES', 'CH'],
      channels: [[
          "https://www.youtube.com/embed/qloe-2D6tPE?si=DO_hfEPMjtxHzWtn&autoplay=1",
          "https://www.youtube.com/embed/qloe-2D6tPE?si=DO_hfEPMjtxHzWtn&autoplay=1",
          "https://www.youtube.com/embed/qloe-2D6tPE?si=DO_hfEPMjtxHzWtn&autoplay=1",
          "https://www.youtube.com/embed/qloe-2D6tPE?si=DO_hfEPMjtxHzWtn&autoplay=1",
        ], [
          "https://www.youtube.com/embed/qloe-2D6tPE?si=DO_hfEPMjtxHzWtn&autoplay=1",
          "https://www.youtube.com/embed/qloe-2D6tPE?si=DO_hfEPMjtxHzWtn&autoplay=1",
          "https://www.youtube.com/embed/qloe-2D6tPE?si=DO_hfEPMjtxHzWtn&autoplay=1",
          "https://www.youtube.com/embed/qloe-2D6tPE?si=DO_hfEPMjtxHzWtn&autoplay=1",
        ],
      ]
    },
  ];

  return (
    <ErrorBoundary>
      <div className="component-plenary">

        <div>
          <LiveYoutube
            setChannelIndex={setChannelIndex}
            channelIndex={channelIndex}
            allChannels={allChannels}
          />

          <div className="component-plenary-rightModules">
            <nav className="component-plenary-rightModules-menu">
              <button type="button" className={clsx(view === VIEW_CHAT && 'act')} onClick={() => setView(VIEW_CHAT)}>{t('plenary.Chat')}</button>
              <button type="button" className={clsx(view === VIEW_POLLS && 'act')} onClick={() => setView(VIEW_POLLS)}>{t('plenary.Polls')}</button>
              {/* <button type="button" className={clsx(view === VIEW_QA && 'act')} onClick={() => setView(VIEW_QA)}>QA</button> */}
            </nav>
            <div className="component-plenary-rightModules-content">
              {view === VIEW_CHAT && <PublicChat tabbed={false} /> }
              {view === VIEW_POLLS && <LiveSondage currentSondage={currentSondage} /> }
              {/* {view === VIEW_QA && <QuestionAnswers /> } */}
            </div>
          </div>

          <nav>
            { allChannels.map(({ name }, index) => <button key={index} type="button" onClick={() => setChannelIndex(index)} className={clsx(index === channelIndex && 'act')}>{name}</button>)}
          </nav>
        </div>

      </div>
    </ErrorBoundary>
  );
};

export default Plenary;
